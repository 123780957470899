// vendors
import React from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"

// components
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Accordion from "../components/Accordion/Accordion"
import BlockContent from "../components/BlockContent/BlockContent"

// utils
import wrapper from "../utils/wrapper"
import mediaQuery from "../utils/media-query"
import { colors, fontSizes } from "../styles/variables"

const FAQPage = ({ data }) => {
  const questions = data.questions.edges.map(({ node }) => node)

  const breakpoint = 1024

  return (
    <Layout>
      <Seo
        description="Vous avez des questions en lien avec les PREM ou les services offerts pour les étudiants en médecine qui cherchent à vivre une expérience en région? Vos réponses ici!"
        lang="fr"
        keywords={[]}
        title="Foire Aux Questions: Stages et emplois médecine en région"
      />

      <article
        css={css`
          ${wrapper.bolt()}
          margin-bottom: ${fontSizes[9] / fontSizes[0]}em;
        `}
      >
        <header
          css={css`
            ${mediaQuery.greaterThen(breakpoint)} {
              ${wrapper.paddingLeft()}
            }
          `}
        >
          <h1>
            Foire aux
            <br />
            questions
          </h1>
        </header>

        <div
          css={css`
            display: grid;
            grid-row-gap: 30px;
            ${mediaQuery.lessThen(breakpoint)} {
              margin-left: calc(50% - 50vw);
              margin-right: calc(50% - 50vw);
            }

            ${mediaQuery.greaterThen(breakpoint)} {
              margin: auto;
              width: 100%;
              max-width: 990px;
            }
          `}
        >
          {questions.map((question) => (
            <Accordion
              header={
                <div
                  css={css`
                    display: flex;
                    font-size: ${fontSizes[1] / fontSizes[0]}em;
                    font-weight: bold;

                    ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
                      font-size: ${fontSizes[3] / fontSizes[1]}em;
                    }
                    :before {
                      content: "—";
                      margin-right: 1ch;
                    }
                  `}
                >
                  {question.question}
                </div>
              }
            >
              <div
                css={css`
                  display: flex;

                  :before {
                    content: "R";
                    font-weight: 900;
                    color: ${colors.blueyGreen};
                    margin-right: 1ch;
                    margin-top: -0.275em;
                    font-size: ${fontSizes[2] / fontSizes[0]}em;

                    ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
                      margin-top: -0.5em;
                      font-size: ${fontSizes[5] / fontSizes[1]}em;
                    }
                  }
                `}
              >
                <BlockContent blocks={question._rawAnswer} />
              </div>
            </Accordion>
          ))}
        </div>
      </article>
    </Layout>
  )
}

export default FAQPage

export const query = graphql`
  query FAQPage {
    questions: allSanityQuestion {
      edges {
        node {
          question
          _rawAnswer
        }
      }
    }
  }
`
